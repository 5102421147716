import React from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "../components/layout/layout";
import {graphql, useStaticQuery, Link} from "gatsby";
import ProductList from "../components/ProductList/ProductList"
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";

const IndexPage = ({location, pageContext, data}) => {


    const products = data.allMdx.nodes;
    const locale = getLocaleFromPath(location.pathname);
    return (
        <Layout location={location}>
            <div className="MainContent">
                <section>
                    <div className="container">
                    <h2>Náš sortiment</h2>
                <div className="buttonHolder">
                <Link className="button" to={"/"+locale+"/produkty"}>Všetky produkty</Link>
                {data.Menu.group.map((node) =>
             <Link className="button" to={"/"+locale+"/produkty/"+node.category}>{node.category}</Link>
          )}
                </div>
                <br/>
                        <ProductList locale={locale} items={products}/>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default withI18n()(IndexPage);


export const pageQuery = graphql`
query Categories($category: [String]){
    allMdx(filter: {frontmatter: {kind: {eq: "product"}, language: {eq: "sk"}, category: {in: $category}}}) {
        nodes {
          id
          frontmatter {
            id
            kind
            language
            title
            uri
            priceVat
            priceNoVat
            option
            vat
            unit
            gallery {
              image {
                big: childImageSharp {
                  fluid(maxWidth: 1200) {
                    src
                  }
                }
                small: childImageSharp {
                  fluid(maxWidth: 400) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    Menu: allMdx(filter: {frontmatter: {kind: {eq: "product"}}}) {
        group(field: frontmatter___category) {
          category: fieldValue
          nodes {
            frontmatter {
                language
            }
          }
        }
      }
}
`;
